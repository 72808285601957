<template>
  <div>
    <vf-link class="block py-4 text-base" to="/account/loyalty">
      <base-interpolator
        :content="replaceAll($t.loyaltyRewardsDetails.pointsAmount, {
          amount: getLocalizedNumber(vouchers?.currentLoyaltyPoints || 0),
        })"
      >
        <template #bold="{ args: [message] }">
          <span class="fw-bold">
            {{ message }}
          </span>
        </template>
      </base-interpolator>
    </vf-link>
    <vf-milestone-bar
      v-if="showLoyaltyRewardsMilestoneBar"
      :milestones
      :offset
      :value="vouchers?.currentLoyaltyPoints"
    />
  </div>
</template>

<script setup lang="ts">
import type { LoyaltyVoucher } from '#types/cart'

const props = defineProps<{
  vouchers: {
    currentLoyaltyPoints: number
    applicableLoyaltyVouchers: LoyaltyVoucher[]
  }
}>()

const { milestoneCount } = useAppConfig().components.vf.loyalty
const { showLoyaltyRewardsMilestoneBar } = useFeatureFlags()
const displayVouchers = ref(props.vouchers?.applicableLoyaltyVouchers)
const offset = ref(0)

if (milestoneCount && displayVouchers.value.length > milestoneCount) {
  const activeIndex = props.vouchers?.applicableLoyaltyVouchers.findLastIndex(({ available }) => available) || 0
  const isOffset = activeIndex >= milestoneCount - 1

  const max = isOffset ? activeIndex + 2 : milestoneCount
  const min = max - milestoneCount

  if (isOffset) offset.value = props.vouchers?.applicableLoyaltyVouchers[min - 1].points
  displayVouchers.value = displayVouchers.value.slice(min, max)
}

const milestones = displayVouchers.value.map((item) => ({
  label: `${useFormattedPrice(Number(item.amountInCurrency), useCurrencyCode(), { minimumFractionDigits: 0 })}`,
  count: item.points,
  text: getLocalizedNumber(item.points),
}))
</script>
