<template>
  <div class="mt-2 bg-grey-90 px-4 py-4 -mx-4">
    <div v-html="rewardPointsExpireInText" />
    <vf-link to="/account/loyalty?pageName=history">
      {{ $t.viewPointsHistory }}
    </vf-link>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  vouchers: {
    expiringLoyaltyPoints: number
    expiringLoyaltyPointsDays: number
  }
}>()

const { $t } = useNuxtApp()
const {
  expiringLoyaltyPoints: points,
  expiringLoyaltyPointsDays: days,
} = props.vouchers

const rewardPointsExpireInText = replaceAll($t.rewardPointsExpireInNextDays, { points, days })
</script>
